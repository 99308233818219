import React ,{ useState, useEffect } from 'react'
import '../Css/Course.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleDot, faList,faTag,faCalendarCheck,faLocationDot} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Seminar from './Seminar';
function Course() {
  const courseApply = () => {
    const url = "https://www.homerepairlearning.com/courseX.php?lessonCode=HRE01&courseCode=HRE";
    window.open(url,'_blank');
  }
  const seminarRegist = () => {
    const url = "https://homerepairlearning.com/seminarApply.php";
    window.open(url, '_blank');
  }  

  const [result, setResult] = useState([]);

  useEffect(() => {
    let resultData = [];
    let fData = new FormData;
    const url = "https://homerepairlearning.com/getHRE.php";
    async function postData() {
        const response = await axios.post(url,fData);
        resultData = response.data;
        setResult(resultData);
        console.log("Course Response",response);

    }
    postData();
  }, []);
  console.log("Course Result", result);

  return (
    <div className="Course-Wrapper">
      <div className="Course-Content">
        <div className="Course-Header">
            <div className="Course-Title">維修王證書課程-最全面的家居維修從業員課程.</div>
        </div>
        <div className="Course-Body">
            <div className="Course-Header-Image">
              <img src={require('../Image/Course.png')} style={{width:'1000px',height:'300px'}} alt="維修王"/>
            </div>
            <div className="Course-Body-Context">
                <div className="Course-Body-Header" >課程宗旨 &nbsp;<FontAwesomeIcon icon={faCircleDot} /> </div>  
                <div className="Course-Body-Content">
                  <p>不論你是新入職或想轉行,從事家居維修確是一個不錯的選擇.一個信譽良優良的家居維修帥傅,月入可高達HK$40,000至HK$50,000.</p>
                  <p>除身酬不錯之外,工作自由度非常高,也是很多人夢寐以求的理想職業...</p>                    
                  <p>好可惜,這個項業入行不易.市面上未有正統課程供有志入行人事供讀.一般入行方去皆是要熟人介照,跟師傅邊學邊做.</p>
                  <p>這種傳統跟師方法,帶出以下問題:</p>
                  <ul>
                    <li>師父技術水平,參差不齊.學員好容易學壞師...</li>
                    <li>教導方式沒有系統,學員好難掌握...</li>
                    <li>由於家居維修項目繁多,要在短時間掌握實在不易,傳統師徒制,學成時間平均長度四年...</li>
                    <li>在傳統師徒制中,大部份教授過程都是在實際工作中完成,由於師傅邊做邊教,學徒只能從傍領會.因此缺乏實戰經驗,學習效果自然不理想...</li>
                  </ul>
                  <div id="header">突破傳統,創辦家居維修人員專業課程:</div>
                  <p>有見以上問題,我們與#家居維修學院,合辦了全港首個家居維修人員專業課程,為有志入行的朋友,提供專業,有系統家居維修課程,課程有以下幾個優點:</p>
                  <ul>
                    <li>課程有別於市面的家居維修DIY班,課程主要教導專業的維修技巧,要求學員需在短時間完成專業維修服務.</li>
                    <li>所有導師不但擁有多年家居維修經驗,並且教學經驗豐富,令學員更易理解..</li>
                    <li>除了課堂學習外,我們提供家居維修實習場地供學員實習,務使學員在短時間內掌握家居維修技巧..</li>
                    <li>完成課程後,學員需進行維修王測試,訓利通過考核後,學員可正式成為維修王..</li>
                    <li>畢業學員,可使用我們研發的維修平台,接柯打..</li>
                  </ul>
                </div>    
            </div>       
            <div className="Course-Body-Context">
              <div className="Course-Body-Header">課程內容&nbsp;<FontAwesomeIcon icon={faList} /> </div>  
                <div className="Course-Body-Content">
                  <div className="Course-Body-List">
                    <div className="Course-Body-ListItem">
                      <ul>
                        <li>玻璃膠技巧</li>
                        <li>安裝鋁質天花</li>
                        <li>安裝鋁質天花</li>
                        <li>安裝廁所五金、瓦仔轉運用</li>
                        <li>安裝座廁（更換零件）</li>
                        <li>安裝洗手盆水龍頭</li>
                        <li>安裝浴缸水龍頭</li>
                        <li>更換洗手盆去水及隔氣</li>
                        <li>更換廁所門鎖</li>
                        <li>更換廁所抽氣扇</li>
                      </ul>
                    </div>
                    <div className="Course-Body-Item">
                      <ul>
                        <li>更換洗手盆水龍頭</li>
                        <li>更換抽油煙機</li>
                        <li>更換櫃桶路軌</li>
                        <li>更換門鉸</li>
                        <li>更換鋁窗鉸</li>
                        <li>更換鋁窗七字鉸</li>
                        <li>安裝吊櫃技巧</li>  
                        <li>安裝木地板</li>  
                        <li>修補瓷磚</li>
                        <li>批灰掃乳膠漆</li>
                      </ul>  
                    </div>  
                    <div className="Course-Body-Item">
                      <ul>
                        <li>石屎剝落維修</li>
                        <li>淘寶安裝燈</li>
                        <li>淘寶砌傢俬</li>
                        <li>清拆技巧</li>
                        <li>清潔家居技巧</li>
                        <li>清洗冷氣</li>  
                        <li>裝修維修報價技巧</li>  
                      </ul>  
                    </div>  
                </div>
            </div>
          </div>
          <div className="Course-Body-Context">
            <div className="Course-Detail-Wrapper">
              <div className="Course-Detail">
                <div className="Course-Detail-Header">
                  <div className="Course-Header-Logo">
                    <FontAwesomeIcon icon={faTag} style={{ color: "00695c", }} />
                  </div>  
                  <div className="Course-Header-Text">
                    費用
                  </div>  
                </div>
                <div className="Course-Detail-Body">
                  HK$17,800
                </div>
              </div>
              <div className="Course-Detail">
                <div className="Course-Detail-Header">
                  <div className="Course-Header-Logo">
                    <FontAwesomeIcon icon={faCalendarCheck} style={{ color: "#1565c0", }}/>
                  </div>
                  <div className="Course-Header-Text">
                    上課時間
                  </div>
                </div>
                <div className="Course-Detail-Body">
                  <div id="Course-Time-Table">
                    {result.map(items => {
                    return (
                      <>
                        <div> {items.CourseDate1} | 星期{items.DayOfWeek1} |{items.CourseTime1}</div>
                        <div> {items.CourseDate2} | 星期{items.DayOfWeek1} |{items.CourseTime1}</div>  
                        <div>  {items.CourseDate3} | 星期{items.DayOfWeek1} |{items.CourseTime1}</div> 

                      </>
                      )
                    })}  
                  </div>  
                </div>
                <div className="Course-Detail-Body">
                  <div id="Course-Time-Table-Mobile">
                  {result.map(items => {
                    return (
                      <>
                        <div className="Course">
                          <div>{items.CourseDate1}</div>
                          <div>星期{items.DayOfWeek1} </div>
                          <div>{items.CourseTime1}</div>
                        </div>
                        <div className="Course">
                          <div>{items.CourseDate2}</div>
                          <div>星期{items.DayOfWeek1} </div>
                          <div>{items.CourseTime1}</div>
                        </div>
                        <div className="Course">
                          <div>{items.CourseDate3}</div>
                          <div>星期{items.DayOfWeek1} </div>
                          <div>{items.CourseTime1}</div>
                        </div>
                      </>
                      )
                  })}
                  </div>  
                </div>  
              </div>
              <div className="Course-Detail">
                  <div className="Course-Detail-Header">
                    <div className="Course-Header-Logo">
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: "#e91e63", }} />
                    </div>  
                    <div className="Course-Header-Text">
                      上課地點
                    </div>
                  </div>
                  <div className="Course-Detail-Body">
                    新蒲崗八達街9號,宏基中心二期-601室
                  </div>
              </div>
            </div>  
          </div>  
          <div className="Course-Body-Context">
            <div className="Course-Button">
               <button className="Button-Body" onClick={courseApply}>立即報名 &nbsp; <FontAwesomeIcon icon={faAngleRight} style={{color:"#ffffff",}} /></button>
            </div>
          </div>  
          
            <Seminar/>
          
      
        </div>
      </div>
    </div>  
  )
}

export default Course
