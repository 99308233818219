import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhone, faRegistered, faVenusMars, faPerson, faIdCard, faClock, faEarthAsia, faStamp, faFaceDizzy, faHeartCirclePlus,
    faQuestionCircle,faEnvelope,faCirclePlus,faCircleMinus,faUser
} from '@fortawesome/free-solid-svg-icons';
import '../Css/SeminarApply.css';
import { toast } from 'react-toastify';

function SeminarApply() {
    //get seminar date and time
     useEffect(() => {
      let resultData = [];
      let fData = new FormData;
      const url = "https://homerepairlearning.com/getAllSeminarInfor.php";
      async function postData() {
          const response = await axios.post(url,fData);
          resultData = response.data;
          setSeminarInfor(resultData);
          console.log("Response",response);

      }
      postData();
     }, []); 
  
  //get the open seminar date
  const handleApplication = (e) => {
    e.preventDefault();
    let data = [];
    let fData = new FormData;
    //Personal Information 1
    fData.append('name1', name1);
    fData.append('phone1', phoneNum1);
    fData.append('email1', email1);
    fData.append('gender1', gender1);

    //Personal Information 2
    fData.append('name2', name2);
    fData.append('phone2', phoneNum2);
    fData.append('email2', email2);
    fData.append('gender2', gender2);

    //Personal Information 3
    fData.append('name3', name3);
    fData.append('phone3', phoneNum3);
    fData.append('email3', email3);
    fData.append('gender3', gender3);

    //Personal Information 4
    fData.append('name4', name4);
    fData.append('phone4', phoneNum4);
    fData.append('email4', email4);
    fData.append('gender4', gender4);
      
    //Personal Information 5
    fData.append('name5', name5);
    fData.append('phone5', phoneNum5);
    fData.append('email5', email5);
    fData.append('gender5', gender5);
    
    //Course Take Information
    //loop via courseTakeArray
    fData.append('courseTake', courseTake);
   

    //send and get response from php file
    const url = "https://homerepairlearning.com/seminarHoldReact.php";
    async function postData() {
        const response = await axios.post(url, fData);
        data = response.data;
        
       
        //result
        if (data === 'Success') {
            console.log("Success !")
            toast.success("留位成功,請盡時出席", {
                position:"top-right",
            });
            
        }else{
            console.log("Registry Failed !");
            toast.error("留位失敗", {
                position:"top-right",
            });
        }
        
    }
    postData();
 }

  
  const deletePerson2 = () => {
    setShowPerson2(false);
    //reset Person2 information
    setName2(""); 
    setPhoneNum2(""); 
    setGender2("");
    setEmail2("");

    console.log("delete Person2 information");
  }
  const deletePerson3 = () => {
    setShowPerson3(false);
    setName3(""); 
    setPhoneNum3(""); 
    setGender3("");
    setEmail3("");

    console.log("delete Person3 information");
  }
  const deletePerson4 = () => {
    setShowPerson4(false);
    setName4(""); 
    setPhoneNum4(""); 
    setGender4("");
    setEmail4("");

    console.log("delete Person4 information");
  }
  const deletePerson5 = () => {
    setShowPerson5(false);
    setName5(""); 
    setPhoneNum5(""); 
    setGender5("");
    setEmail5("");

    console.log("delete Person5 information");
  }

  const addPerson2 = () => {
    setShowPerson2(true);
    console.log("Add Person2 information");
  }
  const addPerson3 = () => {
    setShowPerson3(true);
    console.log("Add Person3 information");
  }
  const addPerson4 = () => {
    setShowPerson4(true);
    console.log("Add Person4 information");
  }
  const addPerson5 = () => {
    setShowPerson5(true);
    console.log("Add Person5 information");
  }


  const [phoneNum1, setPhoneNum1] = useState("");
  const [name1, setName1] = useState("");
  const [gender1, setGender1] = useState("");
  const [email1, setEmail1] = useState("");
  
  const [name2, setName2] = useState("");
  const [phoneNum2, setPhoneNum2] = useState("");
  const [gender2, setGender2] = useState("");
  const [email2, setEmail2] = useState("");

  const [name3, setName3] = useState("");
  const [phoneNum3, setPhoneNum3] = useState("");
  const [gender3, setGender3] = useState("");
  const [email3, setEmail3] = useState("");

  const [name4, setName4] = useState("");
  const [phoneNum4, setPhoneNum4] = useState("");
  const [gender4, setGender4] = useState("");
  const [email4, setEmail4] = useState("");

  const [name5, setName5] = useState("");
  const [phoneNum5, setPhoneNum5] = useState("");
  const [gender5, setGender5] = useState("");
  const [email5, setEmail5] = useState("");

  

  const [showPerson2, setShowPerson2] = useState(false);
  const [showPerson3, setShowPerson3] = useState(false);
  const [showPerson4, setShowPerson4] = useState(false);
  const [showPerson5, setShowPerson5] = useState(false);
  
  const [seminarInfor, setSeminarInfor] = useState([]);

  const [courseTake, setCourseTake] = useState();
  
  
  
  

  

  return (
    <div className="SeminarForm">
      <form className="applicationForm" onSubmit={handleApplication}>
        <div className="formHeader">
          <FontAwesomeIcon icon={faRegistered} />&nbsp;&nbsp;留位...
        </div>  
      <hr />
      <br />
      <div className="PersonalInformation">
        <div className="Personal">
          <div className="Input-group">
            <label for="name">&nbsp;<b><FontAwesomeIcon icon={faFaceDizzy} />&nbsp;&nbsp;英文姓名:</b></label>
            &nbsp;  
            <input type="text"
                  className="form-control"
                  onChange={(e) => setName1(e.target.value)}
                  required
            /> 
          </div>
          <div className="Input-group">
            <label for="email">&nbsp;<b><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;電郵:</b></label>
            &nbsp;
            <input type="email"
                  className="form-control"
                  onChange={(e) => setEmail1(e.target.value)}
            /> 
          </div>
            
          <div className="Input-group">
            <div onChange={(e) => setGender1(e.target.value)}>
                <div className="gender">
                  <label for="gender">&nbsp;<b><FontAwesomeIcon icon={faVenusMars} />&nbsp;&nbsp;性別:</b></label>&nbsp;
                  <input type="radio" value="male" name="gender1"/>男&nbsp;
                  <input type="radio" value="female" name="gender1" />女
                </div>  
            </div>
          </div>
          <div className="Input-group">            
              <label for="phoneNum">&nbsp;<b><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;電話號碼:</b></label>&nbsp;
              <input type="text"
                className="form-control"
                onChange={(e) => setPhoneNum1(e.target.value)}
                required
                minLength="8"
                maxLength="8"
              /> 
          </div>
          <br/> 
          <div className="Course-Button">
            <button className="Button-Body" onClick={addPerson2}>增加一名朋友 &nbsp; <FontAwesomeIcon icon={faCirclePlus} style={{color:"#ffffff",}} /></button>
          </div>  
         <br/>
        </div>    
          {showPerson2 ?
            <div className="Personal">
              <div className="PersonalHeader">
                <div className="Personal-Title">第二位朋友資料&nbsp;<FontAwesomeIcon icon={faUser} /></div>
                <div className="Personal-Button">
                  <button className="RemoveButton" onClick={deletePerson2}>刪除</button>
                </div>  
              </div>
              
              <div className="Input-group">
                <label for="name">&nbsp;<b><FontAwesomeIcon icon={faFaceDizzy} />&nbsp;&nbsp;英文姓名:</b></label>
                <input type="text"
                  className="form-control"
                  onChange={(e) => setName2(e.target.value)}
                  required
                />
              </div>
              <div className="Input-group">
                <label for="email">&nbsp;<b><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;電郵:</b></label>
                <input type="email"
                  className="form-control"
                  onChange={(e) => setEmail2(e.target.value)}
                />
              </div>
              <div className="Input-group">
                <div onChange={(e) => setGender2(e.target.value)}>
                  <div className="gender">
                    <label for="gender"><FontAwesomeIcon icon={faVenusMars} />&nbsp;<b>性別:</b></label>&nbsp;&nbsp;
                    <input type="radio" value="male" name="gender2" />男&nbsp;
                    <input type="radio" value="female" name="gender2" />女
                  </div>
                </div>
              </div>
              <div className="Input-group">
                <label for="phoneNum">&nbsp;<b><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;電話號碼:</b></label>&nbsp;
                <input type="text"
                  className="form-control"
                  onChange={(e) => setPhoneNum2(e.target.value)}
                  required
                  minLength="8"
                  maxLength="8"
                />
              </div>
                           
              <div className="Course-Button">
                <button className="Button-Body" onClick={addPerson3}>增加一名朋友 &nbsp; <FontAwesomeIcon icon={faCirclePlus} style={{ color: "#ffffff", }} /></button>
              </div>
              <br/>
              
            </div>
            : ""}   
            
          {showPerson3 ?
            <div className="Personal">
              <div className="PersonalHeader">
                <div className="Personal-Title">第三位朋友資料&nbsp;<FontAwesomeIcon icon={faUser} /></div>
                <div className="Personal-Button">
                  <button className="RemoveButton" onClick={deletePerson3}>刪除</button>
                </div>  
              </div>
              <div className="Input-group">
                <label for="name">&nbsp;<b><FontAwesomeIcon icon={faFaceDizzy} />&nbsp;&nbsp;英文姓名:</b></label>
                <input type="text"
                  className="form-control"
                  onChange={(e) => setName3(e.target.value)}
                  required
                />
              </div>
              <div className="Input-group">
                <label for="email">&nbsp;<b><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;電郵:</b></label>
                <input type="email"
                  className="form-control"
                  onChange={(e) => setEmail3(e.target.value)}
                />
              </div>
              <div className="Input-group">
                <div onChange={(e) => setGender3(e.target.value)}>
                  <div className="gender">
                    <label for="gender"><FontAwesomeIcon icon={faVenusMars} />&nbsp;<b>性別:</b></label>&nbsp;&nbsp;
                    <input type="radio" value="male" name="gender1" />男&nbsp;
                    <input type="radio" value="female" name="gender1" />女
                  </div>
                </div>
              </div>
              <div className="Input-group">
                <label for="phoneNum">&nbsp;<b><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;電話號碼:</b></label>&nbsp;
                <input type="text"
                  className="form-control"
                  onChange={(e) => setPhoneNum3(e.target.value)}
                  required
                  minLength="8"
                  maxLength="8"
                />
              </div>
              <div className="Course-Body-Context">
                <div className="Course-Button">
                  <button className="Button-Body" onClick={addPerson4}>增加一名朋友 &nbsp; <FontAwesomeIcon icon={faCirclePlus} style={{ color: "#ffffff", }} /></button>
                </div>
            
              </div>
            </div>
            : ""} 
          {showPerson4 ?
            <div className="Personal">
              <div className="PersonalHeader">
                <div className="Personal-Title">第四位朋友資料&nbsp;<FontAwesomeIcon icon={faUser} /></div>
                <div className="Personal-Button">
                  <button className="RemoveButton" onClick={deletePerson4}>刪除</button>
                </div>  
              </div>
              
              <div className="Input-group">
                <label for="name">&nbsp;<b><FontAwesomeIcon icon={faFaceDizzy} />&nbsp;&nbsp;英文姓名:</b></label>
                <input type="text"
                  className="form-control"
                  onChange={(e) => setName4(e.target.value)}
                  required
                />
              </div>
              <div className="Input-group">
                <label for="email">&nbsp;<b><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;電郵:</b></label>
                <input type="email"
                  className="form-control"
                  onChange={(e) => setEmail4(e.target.value)}
                />
              </div>
              <div className="Input-group">
                <div onChange={(e) => setGender4(e.target.value)}>
                  <div className="gender">
                    <label for="gender"><FontAwesomeIcon icon={faVenusMars} />&nbsp;<b>性別:</b></label>&nbsp;&nbsp;
                    <input type="radio" value="male" name="gender4" />男&nbsp;
                    <input type="radio" value="female" name="gender4" />女
                  </div>
                </div>
              </div>
              <div className="Input-group">
                <label for="phoneNum">&nbsp;<b><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;電話號碼:</b></label>&nbsp;
                <input type="text"
                  className="form-control"
                  onChange={(e) => setPhoneNum4(e.target.value)}
                  required
                  minLength="8"
                  maxLength="8"
                />
              </div>
              <div className="Course-Body-Context">
              
                <div className="Course-Button">
                  <button className="Button-Body" onClick={addPerson5}>增加一名朋友 &nbsp; <FontAwesomeIcon icon={faCirclePlus} style={{ color: "#ffffff", }} /></button>
                </div>
              </div>
            </div>
            : ""}
          {showPerson5 ?
            <div className="Personal">
              <div className="PersonalHeader">
                <div className="Personal-Title">第五位朋友資料&nbsp;<FontAwesomeIcon icon={faUser} /></div>
                <div className="Personal-Button">
                  <button className="RemoveButton" onClick={deletePerson5}>刪除</button>
                </div>  
              </div>
              <div className="Input-group">
                <label for="name">&nbsp;<b><FontAwesomeIcon icon={faFaceDizzy} />&nbsp;&nbsp;英文姓名:</b></label>
                <input type="text"
                  className="form-control"
                  onChange={(e) => setName5(e.target.value)}
                  required
                />
              </div>
              <div className="Input-group">
                <label for="email">&nbsp;<b><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;電郵:</b></label>
                <input type="email"
                  className="form-control"
                  onChange={(e) => setEmail5(e.target.value)}
                />
              </div>
              <div className="Input-group">
                <div onChange={(e) => setGender5(e.target.value)}>
                  <div className="gender">
                    <label for="gender"><FontAwesomeIcon icon={faVenusMars} />&nbsp;<b>性別:</b></label>&nbsp;&nbsp;
                    <input type="radio" value="male" name="gender5" />男&nbsp;
                    <input type="radio" value="female" name="gender5" />女
                  </div>
                </div>
              </div>
              <div className="Input-group">
                <label for="phoneNum">&nbsp;<b><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;電話號碼:</b></label>&nbsp;
                <input type="text"
                  className="form-control"
                  onChange={(e) => setPhoneNum5(e.target.value)}
                  required
                  minLength="8"
                  maxLength="8"
                />
              </div>
              
            </div>
            : ""} 
      </div>
      <div className="SeminarInfor">
          <div className="Input-group">
              <div onChange={(e) => setCourseTake(e.target.value)}>  
                  {seminarInfor.map(items=>{
                    return (
                      <tr>
                        <input type="radio" value={items.LessonCode} name="courseTake" required/>
                        <td>{items.Name} </td>
                        <td>{items.LessonCode}</td>
                        <td>{items.CourseDate1} |星期 {items.DayOfWeek1}| {items.CourseTime1}</td>
                        
                      </tr>  
                    )  
                  })}
              </div>    
            </div>    
        </div>
        <br />
        <button type="submit" className="registry-btn"><FontAwesomeIcon icon={faRegistered} />&nbsp;&nbsp;註冊</button>        
        
      </form>  
    </div>  
    
  )
}

export default SeminarApply
