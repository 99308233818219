import React from 'react';
import '../Css/MediaBar.css';
import { FaWhatsapp } from "react-icons/fa";

function MediaBar() {
  return (
    <div className="MediaBarWrapper">
        <div className="MediaBarContent">
            <div className="WhatsApp">
                <div className="MediaLogo">
                    <FaWhatsapp />  
                </div>
                <div className="MediaText" > 
                    <a href="https://wa.me/85291031197">WhatsApp</a>
                </div>      
            </div>    
        </div>      
    </div>
      
    
  )
}

export default MediaBar
