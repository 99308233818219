import React from 'react';
import NavBar from '../Shared/NavBar';
import HomeScreen from './HomeScreen';
import MainService from './MainService';
import Footer from '../Shared/Footer';
function Home() {
    return (
    <>
      <HomeScreen />
      <MainService />  
   
    </>
    
  )
}

export default Home
