import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../Shared/Logo';
import '../Css/NavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import FindHelper from '../Components/FindHelper';



function NavBar({ itemSelect }) {
    const [showLinks, setShowLinks] = useState(false);
    const navigate = useNavigate();
    
    const [HomeState, setHomeState] = useState(true);
    const [AboutState, setAboutState] = useState(false);
    const [CourseState, setCourseState] = useState(false);
    const [FindHelperState, setFindHelperState] = useState(false);
    const [JoinUsState, setJoinUsState] = useState(false);

    const handleHomeState = () => {
        setHomeState(true);
        setCourseState(false);
        setFindHelperState(false);
        setJoinUsState(false);
        setShowLinks(false);
    }

    
    const handleCourseState = () => {
        setHomeState(false);
        setCourseState(true);
        setFindHelperState(false);
        setJoinUsState(false);
        setShowLinks(false);
    }


    const handleFindHelperState = () => {
        setHomeState(false);
        setCourseState(false);
        setFindHelperState(true);
        setJoinUsState(false);
        setShowLinks(false);
    }


    const handleJoinUsState = () => {
        setHomeState(false);
        setCourseState(false);
        setFindHelperState(false);
        setJoinUsState(true);
        setShowLinks(false);
    }

    console.log("ShowLink", showLinks);
    return (
        <div className="NavBarWrapper">
            <div className="NavBar-Logo">
                <Logo />
            </div>
            <div className="NavBar-Content">
                <div className="NavBar-Links" id={showLinks ? "hidden" : ""}>
                    <Link to="/" className={HomeState == true ? "ActiveLink" : "Link"} onClick={() => handleHomeState()}>
                        主頁
                    </Link>
                    <Link to="/Course" className={CourseState == true ? "ActiveLink" : "Link"} onClick={() => handleCourseState()}>
                        維修王認證課程
                    </Link>
                    <Link to="/FindHelper"  className={FindHelperState == true ? "ActiveLink" : "Link"} onClick={() => handleFindHelperState()}>
                        如何找幫手?
                    </Link>
                    <Link to ="JoinUs"  className={JoinUsState == true ? "ActiveLink" : "Link"} onClick={() => handleJoinUsState()}>
                        成為維修王
                    </Link>
                </div>
                <button className="ToggleBtn" onClick={()=>setShowLinks(!showLinks)}><FontAwesomeIcon icon={faBars } /></button>
            </div>
        </div>
    )
}    

export default NavBar





