import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faComment,faBullseye,faAngleRight,faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import '../Css/MainService.css';
import { useNavigate } from 'react-router-dom';

function MainService() {
    const navigate = useNavigate();

    const onClickHandler=(item)=> {
        if (item == 0) {
            navigate("/Course");
        }
        if (item == 1) {
            navigate("/JoinUs");
        }
        if (item == 2) {
            navigate("/FindHelper");
        }
        
    }

  return (
       <div className="MainServiceWrapper">
        <div className="MainServiceContainer">
            <div className="MainServiceHeader">
                <FontAwesomeIcon icon={faBullseye} />&nbsp;服務
            </div>
            <div className="Underline mx-auto"></div>  
            
            <div className="MainServiceCard">
                <div className="MainServiceCardBody">
                    <div className="CardHeader">
                          <FontAwesomeIcon icon={faCertificate} />&nbsp;維修王證書課程
                    </div>      
                     <div className="CardBodyContent">
                            <ul>
                                <li>
                                    為有志從事家居維修行業的朋友,提供專業培訓課程.
                                </li>
                                <li>
                                    為家居維修的行內人員,提供劃一標準.
                                </li>
                                <li>  
                                    課程由在家居維修,裝修行業,逾十年經驗的負責人設計及教導...
                                </li>
                            </ul>
                        </div>  
                        <div className="CardButtonContent">
                          <button className="CardButton" onClick={()=>onClickHandler(0) }>了解更多&nbsp;<FontAwesomeIcon icon={faAngleRight} /></button>
                        </div>  
                    </div>        
                    <div className="MainServiceCardBody">
                        <div className="CardHeader">
                            <FontAwesomeIcon icon={faHandshake}/>&nbsp;誠邀維修師傅加盟
                        </div>
                        <div className="CardBodyContent">
                           <ul>
                                <li>為了劃一家居維修行業服務水平,我們誠邀有志提升本港家居維修水平的業內從業員加盟,成為註冊維修王.</li>      
                                <li>註冊維修王,可使用我們自家研發的維修王平台,誠接家居維修柯打.</li>   
                                <li>我們深信只要師傅的服務水平得到標準認證,將可提升客戶信心,從而增加師傅收入,達到雙赢局面</li>  
                            </ul>
                            <div className="CardButtonContent">
                                <button className="CardButton" onClick={()=>onClickHandler(1) }>了解更多&nbsp;<FontAwesomeIcon icon={faAngleRight} /></button>                          
                            </div>
                        </div>  
                    </div>        
                    <div className="MainServiceCardBody">
                        <div className="CardHeader">
                            <FontAwesomeIcon icon={faComment} />&nbsp;家居維修服務
                        </div>    
                        <div className="CardBodyContent">
                            <ul>
                                <li>自家研發家居維修平台,用戶只需透過簡單操作,便可配對最合適的維修師傅.</li>      
                                <li>我們維修平台,是免滋擾的,用戶資料不會被公開.</li>     
                                <li>詳盡維修記錄,方便日後跟進.</li>  
                                <li>簡單註冊,便可免費享用.</li>  
                            </ul>
                            <div className="CardButtonContent">
                              <button className="CardButton" onClick={()=>onClickHandler(2) }>了解更多&nbsp;<FontAwesomeIcon icon={faAngleRight} /></button>
                            </div>
                        </div>        
                    </div>        
                </div>
            </div>
        </div>
        
  )
}

export default MainService
   

