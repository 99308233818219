import React from 'react'
import '../Css/Course.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleRight } from '@fortawesome/free-solid-svg-icons';

function FindHelper() {
  const clientLogin = () => {
      const url = "https://homerepairexperthk.com/clientSide";
      window.open(url,'_blank');
  }

  return (
    <div className="Course-Wrapper">
      <div className="Course-Content">
      <div className="Course-Header">
          <div className="Course-Title">提供方便易用,家居維修服務配對平台供各介人士使用</div>
      </div>
      <div className="Course-Body">
      <div className="Course-Header-Image">
            <img src={require('../Image/FindHelp.png')} style={{width:'1000px',height:'300px'}} alt="維修王"/>
        </div>
          <div className="Course-Body-Context">
              <div className="Course-Body-Header">[免滋擾式平台-選項] </div>  
              <div className="Course-Body-Content">
              <p>有見近年推銷電話數量大增,加上當中含有詐騙成份.個人電話號碼私隱性更為重要,尤其對長者朋友.現時市面的維修平台,
                          是把服務申請者的電話號碼,個人資料公開發放.一經申請服務,每每在盒時間收到十多個服價電話回覆.對服務申請者帶來極大困擾.
                      </p>
                      <p>
                        有見及此,我們自發的家居維修平台,是免滋擾式.只有客戶的居住區或,家居維修種類會被發佈至已通過我們註冊的維修師傅.
                        服務申請者的個人資料完全得到保障.因此用戶可安心享用.
                      </p>    
              </div>    
          </div>       
          <div className="Course-Body-Context">
            <div className="Course-Body-Header">[關愛家庭-選項]</div>  
              <div className="Course-Body-Content">
                <p>我們提供關愛家庭選項,維修師傅會以低於市場維修價提供家居維修服務.關愛家庭包括:低收入家庭,長者,傷殘人士.希望透過我們的平台,能改善家居環境.
                </p>
              </div>  
          </div>
          <div className="Course-Body-Context">
          <div className="Course-Button">
            <button className="Button-Body" onClick={clientLogin}>免費使用 &nbsp; <FontAwesomeIcon icon={faAngleRight} style={{color:"#ffffff",}}/></button>
          </div>
        </div>  
      </div>
      </div>
    </div>  
  
    
    
  )
}

export default FindHelper
