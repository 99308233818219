import React from 'react';
import '../Css/Course.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck,faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Seminar from './Seminar';

function JoinUs() {
  const masterLogin = () => {
    const url = "https://homerepairexperthk.com/masterside";
    window.open(url,'_blank');
  }
  const seminarRegist = () => {
    const url = "https://homerepairlearning.com/seminarApply.php";
    window.open(url, '_blank');
  }  


  return (
    <div className="Course-Wrapper">
      <div className="Course-Content">
        <div className="Course-Header">
          <div className="JoinUs-Title">我們歡迎全港盡責任,以客戶為本的維修師傅加盟.</div>
          <div className="JoinUs-Title">希望各位維修師傅可透過我們的平台,提升香港家居維修水平,從而增加收入.</div>
        </div>
        
        <div className="Course-Body">
        <div className="Course-Header-Image">
            <img src={require('../Image/JoinUs.png')} style={{width:'1000px',height:'300px'}} alt="維修王"/>
        </div>
          <div className="Course-Body-Context">
              <div className="JoinUs-Body-Header">
                <p>為了統一維修師傅水平,我們誠邀各師父進行技術測試,測試項目以日常能接觸到的家居維修項.</p>
                <div id="header">測試項目如下:</div>
              </div>
              <hr></hr>
              <div className="Course-Body-Content">
                <div className="Course-Body-List">
                  <div className="Course-Body-ListItem">
                    <ul>
                      <li>玻璃膠技巧</li>
                      <li>安裝鋁質天花</li>
                      <li>安裝鋁質天花</li>
                      <li>安裝廁所五金、瓦仔轉運用</li>
                      <li>安裝座廁（更換零件）</li>
                      <li>安裝洗手盆水龍頭</li>
                      <li>安裝浴缸水龍頭</li>
                      <li>更換洗手盆去水及隔氣</li>
                      <li>更換廁所門鎖</li>
                      <li>更換廁所抽氣扇</li>
                    </ul>
                </div>
                <div className="Course-Body-ListItem">
                    <ul>
                      <li>更換洗手盆水龍頭</li>
                      <li>更換抽油煙機</li>
                      <li>更換櫃桶路軌</li>
                      <li>更換門鉸</li>
                      <li>更換鋁窗鉸</li>
                      <li>更換鋁窗七字鉸</li>
                      <li>安裝吊櫃技巧</li>  
                      <li>安裝木地板</li>  
                      <li>修補瓷磚</li>
                      <li>批灰掃乳膠漆</li>
                    </ul>  
                </div>  
                <div className="Course-Body-ListItem">
                    <ul>
                      <li>石屎剝落維修</li>
                      <li>淘寶安裝燈</li>
                      <li>淘寶砌傢俬</li>
                      <li>清拆技巧</li>
                      <li>清潔家居技巧</li>
                      <li>清洗冷氣</li>  
                      <li>裝修維修報價技巧</li>  
                    </ul>  
                </div>  
              </div>
            </div>  
            <div className="Course-Body-Content">
                <div className="JoinUs-Result">
                <div className="JoinUs-Result-Text">
                    <div id="header">
                      <p>測試合格後,可獲盼發維修王認證,正式成為維修王.</p>
                      <p>各維修王可享用維修王平台接柯打</p>
                    </div>
                  </div>
                  <div className="JoinUs-Result-Logo">
                    <FontAwesomeIcon icon={faCircleCheck} style={{color:"#14A44D",}} />
                  </div>
                </div>
            </div>
          </div>  
          <div className="Course-Body-Context">
            <div className="Course-Button">
                <button className="Button-Body" onClick={masterLogin}>師傅註冊 &nbsp; <FontAwesomeIcon icon={faAngleRight} style={{color:"#ffffff",}} /></button>
              </div>  
          </div>
         
            <Seminar/>
         
          
        </div>
          
        </div>
      </div>
    
  )
}

export default JoinUs
