import React from 'react';
import '../Css/Logo.css';

function Logo() {
    return (
        <div className="LogoWrapper">
            <div className="LogoContent">
                <div className="LogoImage">
                    <img src={require('../Image/HRELogo.png')} style={{width:'53px',height:'60px'}} alt="維修王"/>
                </div>
                <div className="LogoText">
                    <div className="LogoText-Chinese">
                        維修王(社企)
                    </div>
                    <div className="LogoText-English">
                        Home Repair Expert
                    </div>    
                </div>
            </div>
        </div>
        
    
  )
}

export default Logo
