import React, { useEffect,useState} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight,faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../Css/Seminar.css';
import SeminarApply from './SeminarApply';


function Seminar() {
    const [result, setResult] = useState([]);
    const [showSeminarForm, setShowSeminarForm] = useState(false);

      //get seminar date and time
      useEffect(() => {
        let resultData = [];
        let fData = new FormData;
        const url = "https://homerepairlearning.com/getSeminar.php";
        async function postData() {
            const response = await axios.post(url,fData);
            resultData = response.data;
            setResult(resultData);
            console.log("Response",response);
  
        }
        postData();
      }, []);
    
    
    
    const seminarRegist = () => {
        setShowSeminarForm(!showSeminarForm);

      
    }  
    
    return (
    <>
       <div className="Course-Body-Context">
            <div className="SeminarWrapper">
                <div className="Seminar-LeftSide">
                    <div className="SeminarImage">
                        <img src={require('../Image/Seminar.png')} style={{width:'250px',height:'200px'}} alt="維修王"/>
                        </div>
                    <div className="SeminarImage-Mobile">
                        <img src={require('../Image/Seminar.png')} style={{width:'200px',height:'150px'}} alt="維修王"/>
                    </div>
                </div>
                <div className="Seminar-RightSide">
                    <div className="SeminarTime">
                        <table>
                            <thead>
                                <tr>
                                <th>日期</th>
                                <th>時間</th>
                                </tr>
                            </thead>    
                        {result.map(items => {
                            return(
                            <tr>
                                <td>{items.CourseDate1} | 星期{items.DayOfWeek1}</td>
                                    
                                <td>{items.CourseTime1}</td>
                            </tr>    
                            )    
                        })}
                        </table>
                    </div>
                    <div className="Course-Button">
                        <button className="Seminar-Button-Body" onClick={seminarRegist}>留位 &nbsp; <FontAwesomeIcon icon={faPaperPlane} style={{color:"#ffffff",}} /></button>
                    </div>
                    
                </div>   
            </div>  
        </div>
        
        {showSeminarForm?
            <div className="Course-Body-Context">
                <SeminarApply />
            </div>
            : ""
        }
    </>   
    
            
    )
}

export default Seminar;
