import './App.css';
import Home from './Components/Home';
import NavBar from './Shared/NavBar';
import JoinUs from './Components/JoinUs';
import Course from './Components/Course';
import Seminar from './Components/Seminar';
import FindHelper from './Components/FindHelper';
import MediaBar from './Components/MediaBar';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router,Routes,Route } from 'react-router-dom';

import Footer from './Shared/Footer';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router>
        <MediaBar/>
        <NavBar/>        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Course" element = {<Course/>}/>
          <Route path="JoinUs" element={<JoinUs />} />
          <Route path="FindHelper" element={<FindHelper />} />
          <Route path="Seminar" element={<Seminar/>}/>
        </Routes>
        <Footer/>
      </Router>
      <ToastContainer bodyClassName="toastBody"/>  
    </>
  );
}

export default App;
