import React from 'react';
import { Link } from 'react-router-dom';
import '../Css/Footer.css';
function Footer() {
  return (
    <div className="Footer-wrapper">
      <div className="Footer-container">
          <div className="Footer-content">
                <div className="Footer-header">聯絡資料</div>
                <div className="Footer-text">
                    <div>地址:新蒲崗八達街9號,宏基中心二期-601室</div>
                    <div >電話:91031197</div>
                    <div>電郵:homerepairexpert@gmail.com</div>
                </div>
          </div>
      </div>
    </div>


  )
}

export default Footer